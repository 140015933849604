<template>
  <div class="account-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
    >
      <!-- <el-table-column type="index" label="ID" align="center"></el-table-column> -->
      <el-table-column
        prop="tempId"
        label="ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="productPlanName" label="方案" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.productPlanName || "无" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        align="center"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="channelName"
        label="渠道"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="requestTime"
        label="申请时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="virtualAmountCNY"
        label="授信额度(元)"
        align="center"
      >
        <!-- <template slot-scope="scope">{{Math.floor(scope.row.requestAmount / 100)}}</template> -->
      </el-table-column>
      <!-- <el-table-column prop="requestDay" label="期数" align="center"></el-table-column> -->
      <el-table-column
        prop="requestDay"
        label="申请时长（天）"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="orderCount" label="笔数" align="center"></el-table-column> -->
      <el-table-column
        prop="auditorName"
        label="初审客服"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financeName"
        label="复审客服"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reason"
        label="拒绝原因"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
<!--      <el-table-column-->
<!--        prop="label"-->
<!--        label="备注"-->
<!--        align="center"-->
<!--        show-overflow-tooltip-->
<!--      ></el-table-column>-->
      <el-table-column label="操作" align="center" min-width="130">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            circle
            @click="$emit('view', scope.row, false)"
          ></el-button>
          <el-button
            type="success"
            size="mini"
            circle
            icon="el-icon-edit"
            @click="handleEdit(scope.row)"
          ></el-button>
          <!--添加备注-->
<!--          <el-button-->
<!--            type="info"-->
<!--            size="mini"-->
<!--            icon="el-icon-edit-outline"-->
<!--            circle-->
<!--            @click="handleLabel(scope.row)"-->
<!--          ></el-button>-->
          <!--已据记录-->
          <el-button
          type="info"
          size="mini"
          icon="el-icon-document"
          circle
          @click="handleRecord(scope.row)"
        ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 备注 -->
    <edit-dialog
      :isShow="showDialog"
      :memo="memo"
      @editConfirm="labelConfirm"
      @editCancel="editCancel">
    </edit-dialog>
    <!-- 已据记录对话框 -->
    <record-dialog
      :isShow="isShowRecord"
      :orderId="orderId"
    @cancel="recordCancel">
    </record-dialog>
  </div>
</template>

<script>
import service from "../api";
import editMemo from "@/mixins/editMemo";

import editDialog from "@/components/viewBar/reports/editDialog";
import recordDialog from "./record-dialog"
export default {
  mixins:[editMemo],
  components:{editDialog, recordDialog},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    //拿一下currentPage的值
    currentPage: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      showEdit: false,
      isShowRecord: false,
      editData: {},
      orderId:''
    };
  },
  methods: {
    handleEdit(row) {
      let _this = this;
      this.$confirm("是否重审?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return service.refused({ orderId: row.id }).then(res => {
            if (res.code == "00000") {
              this.$message({
                type: "success",
                message: "重审成功!"
              });
              _this.afterSubmit({ pageNum: this.currentPage });
            } else {
              this.$message({
                type: "info",
                message: res.msg
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },

    // 提交后操作
    afterSubmit(refreshParam) {
      this.showEdit = false;
      this.$notify({ type: "success", message: "操作成功！" });
      this.$parent.getList({ pageNum: this.currentPage });
    },
    //已拒记录
    handleRecord(row){
      this.orderId = row.id;
      this.isShowRecord = true;
    },
    recordCancel(){
      this.isShowRecord = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
</style>
